import React from 'react';

import InfoDetails from '../components/InfoDetails';
import InfoGraph from '../components/InfoGraph';
  
const Covid = () => {
  return (
    <div>
      <InfoDetails />
      <InfoGraph />
    </div>
  );
};
export default Covid;